@font-face {
  font-family: 'Google Sans';
  src: url('assets/fonts/ProductSans-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Google Sans';
  src: url('assets/fonts/ProductSans-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Google Sans';
  src: url('assets/fonts/ProductSans-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Google Sans';
  src: url('assets/fonts/ProductSans-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Google Sans';
  src: url('assets/fonts/ProductSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Google Sans';
  src: url('assets/fonts/ProductSans-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}
