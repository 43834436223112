/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;

@import 'bootstrap/dist/css/bootstrap.css';
@import '@angular/material/theming';
@import './app-theme';

@include mat-core();

// ngx toaster
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'ngx-toastr/toastr';
@import 'ngx-toastr/toastr-bs4-alert';

/* angular-cli file: src/styles.css */
@import 'angular-calendar/css/angular-calendar.css';

// Syncfusion styles
// @import '@syncfusion/ej2-base/styles/material.css';
// @import '@syncfusion/ej2-buttons/styles/material.css';
// @import '@syncfusion/ej2-dropdowns/styles/material.css';
// @import '@syncfusion/ej2-inputs/styles/material.css';
// @import '@syncfusion/ej2-navigations/styles/material.css';
// @import '@syncfusion/ej2-popups/styles/material.css';
// @import '@syncfusion/ej2-splitbuttons/styles/material.css';
// @import '@syncfusion/ej2-pdfviewer/styles/material.css';
// @import '@syncfusion/ej2-notifications/styles/material.css';
// @import '@syncfusion/ej2-lists/styles/material.css';
// @import '@syncfusion/ej2-spreadsheet/styles/material.css';
// @import '@syncfusion/ej2-grids/styles/material.css';
// @import '@syncfusion/ej2-angular-documenteditor/styles/material.css';
// @import '@syncfusion/ej2-richtexteditor/styles/material.css';

html,
body {
  height: 100vh;
  width: 100%;
  min-height: 100% !important;
  margin: 0;
  font-family: 'Google Sans', sans-serif;
}

.page-content {
  padding-top: 14px;
  padding-bottom: 14px;
}

.center {
  position: absolute;
  /*it can be fixed too*/
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  /* this to solve "the content will not be cut when the window is smaller than the content": */
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

body button:focus,
body input:focus,
body select:focus,
body textarea:focus {
  outline: none;
}

.heading-icon {
  vertical-align: text-bottom;
}

.shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.fab-button {
  position: fixed !important;
  bottom: 48px;
  right: 14px;
  z-index: 9000;
}

.logo-loader {
  max-width: 120px;
  animation: zoominoutsinglefeatured 1s infinite;
}

.fc-event {
  cursor: pointer;
}

.fc-event-title {
  text-overflow: ellipsis;
}

$custom-typography: mat.define-typography-config(
  $font-family: 'Google Sans',
);

@include mat.core($custom-typography);

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}

.mat-icon {
  overflow: visible !important;
}

.offline-dialog .mat-dialog-container {
  background-color: black;
  opacity: 0.8;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.offline-dialog.cdk-overlay-pane {
  backdrop-filter: blur(3px) !important;
}

.view-builder-container .mat-dialog-container {
  padding: 0;
}

/* For use in src/lib/core/theming/_palette.scss */
// Default colors
$md-primary: (
  50: #f5f4f6,
  100: #e6e4e9,
  200: #d5d3da,
  300: #c4c1cb,
  400: #b8b3bf,
  500: #aba6b4,
  600: #a49ead,
  700: #9a95a4,
  800: #918b9c,
  900: #807b8c,
  A100: #ffffff,
  A200: #ebe3fd,
  A400: #c8aeff,
  A700: #b695ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-accent: (
  50: black,
  100: black,
  200: black,
  300: black,
  400: black,
  500: black,
  600: black,
  700: black,
  800: black,
  900: black,
  A100: black,
  A200: black,
  A400: black,
  A700: black,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);

// Default Theme
$primary: mat-palette($md-primary);
$accent: mat-palette($md-accent);
$warn: mat-palette($mat-pink);

$app-theme-dark: mat-dark-theme($primary, $accent, $warn);
$app-theme-light: mat-light-theme($primary, $accent, $warn);

@include angular-material-theme($app-theme-light);
@include material-custom-theme($app-theme-light);

@include mtx.all-component-themes($app-theme-light);
@include mtx.all-experimental-component-themes($app-theme-light);

.color-scheme-light {
  // @include angular-material-theme($app-theme-light);
  // Example how you can easy add own custom styles
  .card-wrapper {
    color: #2d2d2d;
  }
}

.app-background {
  background: white;
}

.color-scheme-dark {
  @include angular-material-theme($app-theme-dark);
  @include material-custom-theme($app-theme-dark);

  @include mtx.all-component-themes($app-theme-dark);
  @include mtx.all-experimental-component-themes($app-theme-dark);

  // Example how you can easy add own custom styles
  .card-wrapper {
    color: #d0d0d0;
  }

  .card-background {
    background-color: rgba(0, 0, 0, 0.5);
    div {
      background-size: cover;
    }
  }

  .app-background {
    background: black;
  }

  .cis-section-header {
    color: #fff;
  }
}

// sentry error reporting dialog styling
.sentry-error-embed {
  * {
    font-family: 'Google Sans', sans-serif !important;
    text-transform: none !important;
  }

  .powered-by {
    display: none;
  }

  .form-submit {
    display: flex;
    flex-direction: row-reverse;
  }

  .btn {
    height: 40px;
    background-color: var(--mat-primary) !important;
    padding: 10px 15px !important;
    min-width: 88px;
    border-radius: 5px;
    font-size: 14px !important;
    margin-left: 10px !important;
    margin-right: 0 !important;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    border: none !important;
    outline: none !important;

    &:not([disabled]):hover {
      opacity: 0.7;
    }
  }

  .close {
    height: 40px;
    padding: 10px 15px !important;
    min-width: 88px;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    border: none;
    outline: none;
    opacity: 1 !important;
    text-shadow: none !important;
    line-height: unset !important;

    &:not([disabled]):hover {
      background-color: rgba(158, 158, 158, 0.2);
    }
  }
}

.loading-overlay {
  z-index: 9999;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  cursor: progress;
  backdrop-filter: blur(3px);
}

.truncate {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.vector_background {
  // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23000000' fill-opacity='0.04' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
  background-image: url('/assets/asfalt-dark.png') !important;
}

// custom scroll bar
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff00;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 30px !important;

  &:hover {
    background: #555;
  }
}

mat-progress-bar {
  z-index: 100000;
}

.mat-dialog-container {
  overflow: hidden !important;
}

.mat-drawer-content {
  display: flex !important;
  flex-direction: column !important;
}

.spacer {
  flex: 1 1 auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Common Styles */
.width-100 {
  width: 100%;
}

.width-90 {
  width: 90%;
}

.width-80 {
  width: 80%;
}

.width-70 {
  width: 70%;
}

.width-60 {
  width: 60%;
}

.width-50 {
  width: 50%;
}

.width-40 {
  width: 40%;
}

.width-30 {
  width: 30%;
}

.width-20 {
  width: 20%;
}

.width-10 {
  width: 10%;
}

mat-card,
mat-expansion-panel,
mat-toolbar {
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

button:not(.shadow) {
  box-shadow: none !important;
}

.mat-flat-button:hover {
  filter: brightness(0.95);
}

.mat-slide-toggle-thumb {
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.app-border {
  border: 1px solid rgba(0, 0, 0, 0.08) !important;
}

.app-dashed-border {
  border: 1px dashed rgba(0, 0, 0, 0.08) !important;
}

.color-scheme-dark {
  & mat-card,
  mat-expansion-panel,
  mat-toolbar {
    box-shadow: none !important;
    border: 1px solid rgba(255, 255, 255, 0.08);
  }

  .app-border {
    border: 1px solid rgba(255, 255, 255, 0.08) !important;
  }

  .app-dashed-border {
    border: 1px dashed rgba(255, 255, 255, 0.08) !important;
  }

  & button {
    box-shadow: none !important;
  }

  & .mat-slide-toggle-thumb {
    box-shadow: none !important;
    border: 1px solid rgba(255, 255, 255, 0.08);
  }
}

@keyframes load {
  from {
    left: -150px;
  }

  to {
    left: 100%;
  }
}

.skeleton-loader-item {
  position: relative !important;
  overflow: hidden !important;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(
      to right,
      transparent 0%,
      #e8e8e8 50%,
      transparent 100%
    );
    animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

.color-scheme-dark {
  .skeleton-loader-item {
    position: relative !important;
    overflow: hidden !important;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -150px;
      top: 0;
      height: 100%;
      width: 150px;
      background: linear-gradient(
        to right,
        transparent 0%,
        rgba(255, 255, 255, 0.2) 50%,
        transparent 100%
      );
      animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
  }
}

.toast-bottom-center {
  bottom: 43px !important;
}

.ngx-toastr {
  font-size: 14px;
  min-width: 300px !important;
  width: max-content !important;
  max-width: min(90vw, 400px) !important;
  border: none;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

// for speed dial spin
.spin180 {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

eco-fab-speed-dial-trigger {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.cdk-global-scrollblock {
  overflow-y: auto !important;
}

.mat-bottom-sheet-container {
  padding: 0 !important;
}

.allow-cr {
  white-space: pre;
}

@import 'ngx-org-chart/_theming.scss';

.ngx-org-name {
  font-family: 'Google Sans', sans-serif;
}

.ngx-org-title {
  font-size: x-small;
  font-family: 'Google Sans', sans-serif;
}

.ngx-org-warn {
  color: red;
  border-color: red;
}

.ngx-org-box {
  background: none;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }

  .ngx-org-vertical {
    padding: 1em;
  }
}

.toastr-icon-download {
  background-image: url('/assets/icons/drive/download.svg');
}

.toastr-icon-upload {
  background-image: url('/assets/icons/drive/upload.svg');
}

.card-background {
  background-color: white;
  div {
    background-size: cover;
  }
}

.cis-section-header {
  color: #000;
}

.footer-text {
  p {
    display: inline;
  }
  p a {
    color: white;
    text-decoration: none;
  }
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

.ck {
  color: black !important;
}

.ck-editor__editable,
.ck-balloon-panel,
.ck-list,
.ck-toolbar {
  background-color: white !important;
}

.ck-on {
  background: rgba(0, 0, 0, 0.5) !important;
}

.color-scheme-dark {
  .ck-editor__editable,
  .ck-balloon-panel,
  .ck-list,
  .ck-toolbar {
    background-color: #424242 !important;
  }

  .ck {
    color: white !important;
  }

  .ck-on {
    background: rgba(255, 255, 255, 0.5) !important;
  }
}

.document-card {
  background-color: #f1f3f4;
  border-radius: 5px;
}

.color-scheme-dark .document-card {
  background-color: #616161;
}

// Override standard chip size
.mat-standard-chip {
  min-height: 28px !important;
}

// Override the standard mat-select arrow alignment in right side
div.mat-select-arrow-wrapper {
  width: auto !important;
}
