@mixin global-styles-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  // Global Theme Colors
  .primary-bg {
    background-color: mat-color($primary);
  }
  .primary-bg-50 {
    background-color: mat-color($primary, 50, 0.3);
  }
  .primary-bg-100 {
    background-color: mat-color($primary, 100);
  }
  .primary-bg-200 {
    background-color: mat-color($primary, 200);
  }
  .primary-bg-300 {
    background-color: mat-color($primary, 300);
  }
  .primary-bg-400 {
    background-color: mat-color($primary, 400);
  }
  .primary-bg-500 {
    background-color: mat-color($primary, 500);
  }
  .primary-bg-600 {
    background-color: mat-color($primary, 600);
  }
  .primary-bg-700 {
    background-color: mat-color($primary, 700);
  }
  .primary-bg-800 {
    background-color: mat-color($primary, 800);
  }
  .primary-bg-900 {
    background-color: mat-color($primary, 900);
  }
  .primary-bg-A100 {
    background-color: mat-color($primary, A100);
  }
  .primary-bg-A200 {
    background-color: mat-color($primary, A200);
  }
  .primary-bg-A400 {
    background-color: mat-color($primary, A400);
  }
  .primary-bg-A700 {
    background-color: mat-color($primary, A700);
  }
  .accent-bg {
    background-color: mat-color($accent);
  }
  .accent-bg-50 {
    background-color: mat-color($accent, 50);
  }
  .accent-bg-100 {
    background-color: mat-color($accent, 100);
  }
  .accent-bg-200 {
    background-color: mat-color($accent, 200);
  }
  .accent-bg-300 {
    background-color: mat-color($accent, 300);
  }
  .accent-bg-400 {
    background-color: mat-color($accent, 400);
  }
  .accent-bg-500 {
    background-color: mat-color($accent, 500);
  }
  .accent-bg-600 {
    background-color: mat-color($accent, 600);
  }
  .accent-bg-700 {
    background-color: mat-color($accent, 700);
  }
  .accent-bg-800 {
    background-color: mat-color($accent, 800);
  }
  .accent-bg-900 {
    background-color: mat-color($accent, 900);
  }
  .accent-bg-A100 {
    background-color: mat-color($accent, A100);
  }
  .accent-bg-A200 {
    background-color: mat-color($accent, A200);
  }
  .accent-bg-A400 {
    background-color: mat-color($accent, A400);
  }
  .accent-bg-A700 {
    background-color: mat-color($accent, A700);
  }
  .warn-bg {
    background-color: mat-color($warn);
  }

  // Text Theme Colors
  .primary-txt {
    color: mat-color($primary);
  }
  .accent-txt {
    color: mat-color($accent);
  }
  .warn-txt {
    color: mat-color($warn);
  }

  .primary-color {
    color: mat-color($primary) !important;
  }
  .primary-color-default-contrast {
    color: mat-color($primary, default-contrast) !important;
  }
  .primary-color-50 {
    color: mat-color($primary, 50) !important;
  }
  .primary-color-100 {
    color: mat-color($primary, 100) !important;
  }
  .primary-color-200 {
    color: mat-color($primary, 200) !important;
  }
  .primary-color-300 {
    color: mat-color($primary, 300) !important;
  }
  .primary-color-400 {
    color: mat-color($primary, 400) !important;
  }
  .primary-color-500 {
    color: mat-color($primary, 500) !important;
  }
  .primary-color-600 {
    color: mat-color($primary, 600) !important;
  }
  .primary-color-700 {
    color: mat-color($primary, 700) !important;
  }
  .primary-color-800 {
    color: mat-color($primary, 800) !important;
  }
  .primary-color-900 {
    color: mat-color($primary, 900) !important;
  }
  .primary-color-A100 {
    color: mat-color($primary, A100) !important;
  }
  .primary-color-A200 {
    color: mat-color($primary, A200) !important;
  }
  .primary-color-A400 {
    color: mat-color($primary, A400) !important;
  }
  .primary-color-A700 {
    color: mat-color($primary, A700) !important;
  }
  .accent-color {
    color: mat-color($accent) !important;
  }
  .accent-color-default-contrast {
    color: mat-color($accent, default-contrast) !important;
  }
  .accent-color-50 {
    color: mat-color($accent, 50) !important;
  }
  .accent-color-100 {
    color: mat-color($accent, 100) !important;
  }
  .accent-color-200 {
    color: mat-color($accent, 200) !important;
  }
  .accent-color-300 {
    color: mat-color($accent, 300) !important;
  }
  .accent-color-400 {
    color: mat-color($accent, 400) !important;
  }
  .accent-color-500 {
    color: mat-color($accent, 500) !important;
  }
  .accent-color-600 {
    color: mat-color($accent, 600) !important;
  }
  .accent-color-700 {
    color: mat-color($accent, 700) !important;
  }
  .accent-color-800 {
    color: mat-color($accent, 800) !important;
  }
  .accent-color-900 {
    color: mat-color($accent, 900) !important;
  }
  .accent-color-A100 {
    color: mat-color($accent, A100) !important;
  }
  .accent-color-A200 {
    color: mat-color($accent, A200) !important;
  }
  .accent-color-A400 {
    color: mat-color($accent, A400) !important;
  }
  .accent-color-A700 {
    color: mat-color($accent, A700) !important;
  }
  .warn-color {
    color: mat-color($warn) !important;
  }

  .primary-border-color {
    border-color: mat-color($primary) !important;
  }
  .accent-border-color {
    border-color: mat-color($accent) !important;
  }
  .warn-border-color {
    border-color: mat-color($warn) !important;
  }

  // Mouse hover theme colors
  .hover-color-primary {
    :hover {
      color: mat-color($primary) !important;
      background-color: mat-color($primary, 50);
    }
  }
  .hover-color-accent {
    :hover {
      color: mat-color($primary) !important;
      background-color: mat-color($primary, 50);
    }
  }
  .hover-color-warn {
    :hover {
      color: mat-color($primary) !important;
      background-color: mat-color($primary, 50);
    }
  }
}

@mixin material-custom-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  // Other mixin includes
  @include global-styles-theme($theme);
}
